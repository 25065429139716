import Api from "@/services/api";
import {removeEmptyParams} from "@utils";

export default {
    findAll(filter) {
        return Api().get("/bilancriseperiods", {params: removeEmptyParams(filter)}).then(res => {
            res.data.forEach(e => this.formatRes(e));
            return res.data;
        });
    },
    findAllCurrent(filter) {
        return Api().get("/bilancriseperiods/current", {params: removeEmptyParams(filter)}).then(res => {
            res.data.forEach(e => this.formatRes(e));
            return res.data;
        });
    },
    findAllByYear(year) {
        return Api().get("/bilancriseperiods/year/" + year).then(res => {
            res.data.forEach(e => this.formatRes(e));
            return res.data;
        });
    },
    findAllYears() {
        return Api().get("/bilancriseperiods/years/").then(res => res.data);
    },
    findById(id) {
        return Api().get("/bilancriseperiod/" + id).then(res => this.formatRes(res.data));
    },
    findSyntheseByYear(year) {
        return Api().get("/bilancriseperiod/synthese/" + year).then(res => {
            res.data.forEach(e => this.formatRes(e));
            return res.data;
        });
    },
    findSyntheseByCurrentYear() {
        return Api().get("/bilancriseperiod/synthese").then(res => {
            res.data.forEach(e => this.formatRes(e));
            return res.data;
        });
    },
    findCurrentYear() {
        return Api().get("/bilancriseperiod/currentyear").then(res => res.data);
    },
    create(bilanCrise) {
        return Api().post("/bilancriseperiod", bilanCrise).then(res => this.formatRes(res.data));
    },
    update(bilanCrise) {
        return Api().put("/bilancriseperiod/" + bilanCrise.id, bilanCrise).then(res => this.formatRes(res.data));
    },
    delete(bilanCrise) {
        return Api().delete("/bilancriseperiod/" + bilanCrise.id).then(() => bilanCrise);
    },
    formatRes(e) {
        if (e.dateStart) e.dateStart = new Date(e.dateStart);
        if (e.dateEnd) e.dateEnd = new Date(e.dateEnd);
        return e;
    }
}