<script>

/* COMPOSANTS */
import AkFormView from "@components/layout/AkFormView";
import AkDropdown from "@components/input/AkDropdown";

/* MIXINS */
import randomRef from "@mixins/randomRef";
import ceiMixin from "@mixins/ceiMixin";
import roleMixin from "@mixins/roleMixin";
import dateFormatter from "@mixins/dateFormatter";
import multiLineMixin from "@mixins/multiLineMixin";

/* SERVICES */
import bilanCriseService from "@services/bilanCriseService";
import ceiService from "@services/ceiService";
import bilanCrisePeriodService from "@services/bilanCrisePeriodService";

export default {
  components: { AkFormView, AkDropdown },
  mixins: [randomRef, ceiMixin, roleMixin, dateFormatter, multiLineMixin],
  metaInfo() {
    return {
      title: "bilan_crise.synthese",
    }
  },
  data() {
    return {
      year: 0,
      yearList: [],
      crisisList: [],
      crisisPeriodList: [],
      ceiList: [],
      currentCrisis: null,
      loading: false,
      isExportDownloading: false,
    }
  },
  mounted() {
    this.loading = true;
    this.getRef().showTotalLoader();

    let p1 = ceiService.findAllCurrent();
    p1.then(data => this.ceiList = data);

    let p2 = bilanCriseService.findSyntheseByCurrentYear();
    p2.then(data => this.crisisList = data);

    let p3 = bilanCrisePeriodService.findSyntheseByCurrentYear();
    p3.then(data => this.crisisPeriodList = data);

    let p4 = bilanCrisePeriodService.findAllYears();
    p4.then(data => {
      this.yearList.push({ value: 0, label: this.$t('current_year') });
      data.forEach(year => this.yearList.push({ value: year, label: year + ' - ' + (year + 1) }))
    });

    Promise.all([p1, p2, p3, p4]).then(() => this.afterPromise());
  },
  methods: {
    initData() {
      this.initCeiListForTable(this.ceiList);
      this.ceiList.forEach(c => {
        c.crisisMatrice = [];
        this.crisisPeriodList.forEach(cp => {
          let current = this.crisisList.find(cl => {
            if(this.displayArchiveData){
              return cl.snapshotCeiId === c.id && cl.crisePeriodId === cp.id
            }else {
              return cl.ceiId === c.id && cl.crisePeriodId === cp.id
            }
          });
          if (current) c.crisisMatrice[cp.id] = current;
        });
      });
    },
    afterPromise() {
      this.loading = false;
      this.getRef().hideLoader();
      this.initData();
    },
    reload(year) {
      this.loading = true;
      this.getRef().showTotalLoader();
      let p1;
      let p2;
      let p3;
      if (year !== 0) {
        p1 = ceiService.findAllByYear(year);
        p1.then(data => this.ceiList = data);

        p2 = bilanCriseService.findAllByYear(year);
        p2.then(data => this.crisisList = data);

        p3 = bilanCrisePeriodService.findAllByYear(year);
        p3.then(data => this.crisisPeriodList = data);
      } else {
        p1 = ceiService.findAllCurrent();
        p1.then(data => this.ceiList = data);

        p2 = bilanCriseService.findSyntheseByCurrentYear();
        p2.then(data => this.crisisList = data);

        p3 = bilanCrisePeriodService.findSyntheseByCurrentYear();
        p3.then(data => this.crisisPeriodList = data);
      }

      Promise.all([p1, p2, p3]).then(() => this.afterPromise());
    },
    findCrisisProblem(issue) {
      issue = this.getTextParsed(issue);
      if (issue.includes('<br/>')) issue = '<br/>' + issue;
      if (!issue) return '<strong>' + this.$t('bilan_crise.problem_not_found') + '</strong>';
      else if (issue.substring(0, 50) === issue) return '<strong>' + this.$t('bilan_crise.problem_description') + '</strong>' + issue;
      else return '<strong>' + this.$t('bilan_crise.problem_description') + '</strong>' + issue.substring(0, 50) + '...';
    },
    findCrisisSolution(solution) {
      solution = this.getTextParsed(solution);
      if (solution.includes('<br/>')) solution = '<br/>' + solution;
      if (!solution) return '<strong>' + this.$t('bilan_crise.solution_not_found') + '</strong>';
      else if (solution.substring(0, 50) === solution) return '<strong>' + this.$t('bilan_crise.solution_description') + '</strong>' + solution;
      else return '<strong>' + this.$t('bilan_crise.solution_description') + '</strong>' + solution.substring(0, 50) + '...';
    },
		download() {
      this.isExportDownloading = true;
			let p = bilanCriseService.download(this.year);
			let promises = [p];
			p.catch(e=>{
				this.getRef().hideLoader();
				this.getRef().error(this.$t("error_download_synthese"));
			});
			Promise.all(promises).then(() => {
        this.isExportDownloading = false;
      });
		}
  },
  computed: {
    displayArchiveData(){
      return this.year !== 0;
    }
  },
  watch: {
    year(newType) {
      this.reload(newType);
    }
  }
}
</script>

<template v-if=!roleLoading>
  <AkFormView :ref="ref" :title="$t('bilan_crise.list')" :displayGoBack=false>
    <template v-slot:action>
      <button @click="download()" style="margin-right: 10px;" class="btn btn-inverse-primary" :disabled="isExportDownloading">
        <i class="fe fe-download pr-1" v-if="!isExportDownloading"/>
        <i class="pi pi-spin pi-spinner mr-1" v-if="isExportDownloading"></i>
        {{ $t('download_excel') }}
      </button>
      <RouterLink v-if=canEditCrisis :to="{ name: 'bilanCriseList' }" class="btn btn-inverse-primary">
        <i class="fe fe-settings pr-1" />{{ $t('manage') }}
      </RouterLink>
    </template>
    <template v-slot:form v-if=!loading>
      <div class="row">
        <AkDropdown :label="$t('year')" v-if=this.canViewCrisisArchived() v-model=year :options=this.yearList
          class-name="col-md-2" />
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="table-responsive forecast-table">
                <table style="display: block; overflow-x: auto;">
                  <thead>
                    <tr>
                      <th scope="col">{{ $t('ager_label') }}</th>
                      <th scope="col">{{ $t('uer_label') }}</th>
                      <th scope="col">{{ $t('cei_label') }}</th>
                      <th v-for="crisePeriod in crisisPeriodList" :key="crisePeriod.id">{{
                      $t('bilan_crise.period_title', [formatDate(crisePeriod.dateStart),
                      formatDate(crisePeriod.dateEnd)]) }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="cei in ceiList" :key="cei.id">
                      <td v-if=cei.agerSize :rowspan=cei.agerSize>{{ cei.agerLabel }}</td>
                      <td v-if=cei.uerSize :rowspan=cei.uerSize>{{ cei.uerLabel }}</td>
                      <td>{{ cei.label }}</td>
                      <td v-for="crisePeriod in crisisPeriodList" :key="crisePeriod.id" class="text-left">
                        <template v-if="cei.crisisMatrice.length > 0 && cei.crisisMatrice[crisePeriod.id]">
                          <span v-html="findCrisisProblem(cei.crisisMatrice[crisePeriod.id].issue)"
                            :title="cei.crisisMatrice[crisePeriod.id].issue"></span><br />
                          <span v-html="findCrisisSolution(cei.crisisMatrice[crisePeriod.id].solution)"
                            :title="cei.crisisMatrice[crisePeriod.id].solution"></span>
                          <div>
                            <RouterLink
                              v-if="(!cei.crisisMatrice[crisePeriod.id].year && this.canViewCrisis()) || (cei.crisisMatrice[crisePeriod.id].year && this.canViewCrisisArchived())"
                              :to="{ name: 'bilanCriseDetails', params: { id: cei.crisisMatrice[crisePeriod.id].id } }"
                              class="btn btn-xs btn-inverse-primary mr-1 float-right">
                              <i class="fe fe-eye" />
                            </RouterLink>
                          </div>
                        </template>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </AkFormView>
</template>